import { CheckBoxPrimaryWrapper } from './styles'
import { ICheckBoxProps } from './types'
import React from 'react'
import { colors } from '../../styles/theme'
import { paddingToString } from '../../styles/functions'

import { Check } from 'react-feather'

function CheckBox({
	className,
	primaryColor = colors.darkGrey,
	backgroundColor = 'transparent',
	round = false,
	secondaryColor = colors.grey,
	fontSize = 24,
	padding = [2, 2, 2, 2],
	...rest
}: ICheckBoxProps) {
	const paddingString = paddingToString(padding)

	const mergedStyles = {
		primaryColor,
		backgroundColor,
		round,
		secondaryColor,
		fontSize,
		padding: paddingString,
		fontFamily: ''
	}

	return (
		<CheckBoxPrimaryWrapper styles={mergedStyles} className={className}>
			<input
				className='checkbox-input'
				type='checkbox'
				name='itaipava'
				{...rest}
			/>

			<Check className='checkmark' />
		</CheckBoxPrimaryWrapper>
	)
}

export default CheckBox
