export function formatCPF(cpf: string) {
	const document = cpf.replace(/[^\d]/g, '')

	if (cpf.length !== 11) return cpf

	return document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
}

export function formatCNPJ(cnpj: string) {
	const document = cnpj.replace(/[^\d]/g, '')

	return document.replace(
		/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
		'$1.$2.$3/$4-$5'
	)
}

export function formatCnpjCpf(value: string) {
	const document = value.replace(/\D/g, '')

	if (document.length === 11)
		return document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4')

	if (document.length === 14) {
		return document.replace(
			/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
			'$1.$2.$3/$4-$5'
		)
	}

	return document
}

export function formatPhone(value: string) {
	const cellphone = value.replace(/\D/g, '')

	// With +55 and 19
	if (cellphone.length === 14) {
		return cellphone.replace(/(\d{3})(\d{2})(\d{5})(\d{4})/g, '$1 ($2) $3-$4')
	}

	// With 55 and 19
	if (cellphone.length === 13) {
		return cellphone.replace(/(\d{2})(\d{2})(\d{5})(\d{4})/g, '+$1 ($2) $3-$4')
	}

	// With 19
	if (cellphone.length === 11) {
		return cellphone.replace(/(\d{2})(\d{5})(\d{4})/g, '($1) $2-$3')
	}

	// With Telephone 19
	if (cellphone.length === 10) {
		return cellphone.replace(/(\d{2})(\d{4})(\d{4})/g, '($1) $2-$3')
	}

	// if (cellphone.length === 9) {
	// 	return cellphone.replace(/(\d{5})(\d{4})/g, '$1-$2')
	// }

	return cellphone
}

export function formatPostalCode(value: string) {
	const code = value.replace(/\D/g, '')

	return code.replace(/(\d{5})(\d{3})/g, '$1-$2')
}

export function formatRG(value: string) {
	const document = value.replace(/\D/g, '')

	if (document.length === 9) {
		return document.replace(/(\d{2})(\d{3})(\d{3})(\d{1})/g, '$1.$2.$3-$4')
	}

	return document
}
